import React from 'react' 
import Carousel from 'react-bootstrap/Carousel';
import CompOne from './CompOne'; 
import staffone from "../Images/../Images/staff/principle.jpg"; 
import staftwo from "../Images/../Images/staff/director.jpg";
import { Container, Row, Col } from 'react-bootstrap';  
import labone from "../Images/lab/service1.jpg" ; 


const TeamSlider = () => {
  return (
    <> 
        <Container fluid> 
      <Carousel className='principle'>
      <Carousel.Item>  
        <h2 className='mb-5 fs-1 fw-bold'> From The Director Desk</h2>
      <Row> 
            <Col md={6} lg={6}>   
   <p> Education is a friend who does not leave you in time of happiness and misfortune. It 
will help you in every walk of life and so make use of it when you get an opportunity to 
learn in this school.</p> 
<p> Knowledge is endless and so our efforts. Let us make an effort to make this world 
free of this Illiteracy. Let the society be shown the path of unity and enlighten the people 
around us through the valued education we get. So let us take pledge to serve the society 
better.</p> 
<p> We believe in quality and quantity and our efforts would be simply to make the 
process of learning more interesting and appealing to a sundry. I wish all the students and 
the faculties best of luck in their endeavour.</p>
            
             </Col> 
             <Col md={6} lg={6}>   
             <img className='w-50 rounded'  src={staffone} />
            <p className='mt-3 p-sign fw-bold fs-4'> Thank you
Kavita Sihag
Director</p>
             </Col>
            </Row>
   
        {/* <img
          className="d-block w-100"
          src="holder.js/800x400?text=First slide&bg=f5f5f5"
          alt="First slide"
        /> */}
      </Carousel.Item> 

      <Carousel.Item>  
        <h2 className='mb-5 fs-1 fw-bold'> From The Principal Desk </h2>
      <Row> 
            <Col md={6} lg={6}>   
   <p> Today's world of education demand some professionalism and Global International 
School is striving to achieve excellence. The persons involved with the institution are 
working tirelessly to achieve success in this competitive environment.</p> 
<p>  The main feature of this school is the great infrastructure, excellent teaching 
professionals and updated teaching methods. Discipline is a must inside the campus 
and we make efforts to make the students comply with the rules and regulations. We 
offer all the help we can to the aspiring trainees so that they can build a successful life a 
head. Since our objective is to uplift the education system of our society we make sure 
the teachers we employ are young, dynamic and totally committed to transfer 
knowledge to the students through various interactive programmes</p>
            
             </Col> 
             <Col md={6} lg={6}>   
             <img className='w-50 rounded'  src={staftwo} /> 
             <p className='mt-3 p-sign fw-bold fs-4'>Best Wishes
Anil Kumar
Principal
</p>
            
             </Col>
            </Row>
   
        {/* <img
          className="d-block w-100"
          src="holder.js/800x400?text=First slide&bg=f5f5f5"
          alt="First slide"
        /> */}
      </Carousel.Item>

     
    </Carousel> 
    </Container>

    </>
  )
}

export default TeamSlider
