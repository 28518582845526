import React from 'react'; 
import {motion} from "framer-motion" ; 

const CompTwo = (props) => {
  const { CompTwoIcon, CompTwoHeading, CompTwoParagraph } = props;
  return (
    <> 


      <div className="d-flex align-items-center mb-2 learn-section">
        <div className="pe-3">
          <i className={`bi ${CompTwoIcon} fs-4`}></i>
        </div> 
        <motion.div  
 initial = {{opacity:0, scale: 0 ,  y:-500}} 
 whileInView={{opacity:1, scale:1,  y: 0 }} 
 transition={{duration :0.9}}
 
 >
        <h5>{CompTwoHeading}</h5> 
        </motion.div>
      </div>
      <p>{CompTwoParagraph}</p> 

    </>
  );
};

export default CompTwo;

