import React from 'react'
import WebHeader from '../Component/WebHeader' ; 
import { Container, Row, Col,Button,Nav } from 'react-bootstrap';   
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
import pdf from "../Images/cbse/affilation.pdf"; 
import trust from "../Images/cbse/trust.pdf"; 
import Noc from "../Images/cbse/noc.jpg" ;  
import ic from "../Images/cbse/lc.jpg" ;  
import bs from "../Images/cbse/lc.jpg" ; 
import ls from "../Images/cbse/land-certificate.jpg" ;  
import result from "../Images/cbse/last-three-year-results.jpg" ; 
import ptm from "../Images/cbse/ptm.jpg" ; 
import smc from "../Images/cbse/smc.jpg" ; 
import sh from "../Images/cbse/schoolholiday.pdf" ; 
import arh from "../Images/cbse/ARA.pdf";  
import fs from "../Images/cbse/gbs.pdf" ;  
import fe from "../Images/cbse/fe.jpg" ;
import Footer from '../Component/Footer'; 
import one from "../Images/cbse/1.pdf" ;  
import Fs from "../Images/cbse/fee-structure 2024-25.pdf"

const Admission = () => { 
  return (
    <>
      <WebHeader/> 
      <section className='about-us Gallery-section'> 
    <Container>
      <Row className='text-center pt-5 pb-5'>
        <Col className='text-white fs-1 fw-bold'> <p> About Us </p></Col>
      </Row>
    </Container> 
    </section> 

<section className='mt-3'> 
    <Container>  
        <Row> 

    <Row>  
      <h2>GENERAL INFORMATION</h2> 
    </Row> 
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>SL No</th>
          <th>INFORMATION</th>
          <th>DETAILS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>NAME OF THE SCHOOL*</td>
          <td>GLOBAL INTERNATIONAL SCHOOL</td>
        </tr>
        <tr>
          <td>2</td>
          <td>AFFILIATION NO.(IF APPLICABLE)</td>
          <td> 531241 </td>
        </tr>
        <tr>
          <td>3</td>
          <td>SCHOOL CODE (IF APPLICABLE)</td>
          <td> 41223</td>
        </tr>
        <tr>
          <td>4</td>
          <td>COMPLETE ADDRESS WITH PIN CODE*</td>
          <td> GLOBAL INTL SCHOOL VPO ARYA NAGAR HISAR HRY
VPO ARYA NAGAR,
HARYANA,HISSAR, 125001
 </td>
        </tr>
        <tr>
          <td>5</td>
          <td>PRINCIPAL NAME</td>
          <td>ANIL KUMAR </td>
        </tr>
        <tr>
          <td>6</td>
          <td>PRINCIPAL QUALIFICATION*</td>
          <td>M.A B.Ed PGDJMC </td>
        </tr>
        <tr>
          <td>7</td>
          <td>SCHOOL EMAIL ID*</td>
          <td>globalinternationalschool01@gmail.com </td>
        </tr>
        <tr>
          <td>8</td>
          <td>CONTACT DETAILS (LANDLINE/MOBILE)*</td>
          <td> +918222999891 , +918222999892
+918222999893 , +918222999894 </td>
        </tr>
      </tbody>
    </Table>
        <Row>  
            <h2>DOCUMENTS AND INFORMATION</h2> 
        </Row> 
        <Table striped bordered hover className='school-table' >
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>DOCUMENTS/INFORMATION</th>
                    <th>LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY</td>
                    <td> 
                  
                    <a href={arh} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> 
                         View <i className="bi bi-file-pdf  text-danger"></i></Button>  </a> 
                   
                </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE*</td>
                    <td> 
                    <a href={trust} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a> 
                         </td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT*</td>
                    <td>  
                    <a href={Noc} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button> 
                        </a> 
                        </td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE*</td>
                    <td> 
                    <a href={pdf} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button> 
                           </a>  
                        </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE*</td>
                    <td>  
                    <a href={bs} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a>  
                        </td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY*</td>
                    <td>  
                    <a href={fs} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a> 
                        </td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL*</td>
                    <td>  
                    <a href={one} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a>  
                        </td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
                    <td><Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button></td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>LAND CERTIFICATE</td>
                    <td>  
                    <a href={ls} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a>  
                        </td>
                </tr>
            </tbody> 
        </Table> 
        <p className='fs-6'> 
            <span className='fw-bold'>  NOTE: </span> THE SCHOOLS NEED TO UPLOAD THE SELF-ATTESTED COPIES OF ABOVE-LISTED DOCUMENTS BY CHAIRMAN/MANAGER/SECRETARY AND PRINCIPAL. IN CASE, IT IS NOTICED AT A LATER STAGE THAT UPLOADED DOCUMENTS ARE NOT GENUINE, THEN THE SCHOOL SHALL BE LIABLE FOR ACTION AS PER NORMS.
        </p> 

        <Row>  
            <h2>RESULT AND ACADEMICS</h2> 
        </Row> 
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>DOCUMENTS/INFORMATION</th>
                    <th>LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>TRANSPORT FARE WITH FEE STRUCTURE OF THE SCHOOL*</td>
                    <td>  
                    <a href={Fs} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a> 
                        </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>ANNUAL ACADEMIC CALENDAR*</td>
                    <td>  
                    <a href={sh} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button> 
                        </a> 
                        </td> 
                </tr>
                <tr>
                    <td>3</td>
                    <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)*</td>
                    <td>  
                    <a href={smc} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a> 

                        </td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS*</td>
                    <td>   <a href={ptm} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button> 
                        </a>
                        </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY</td>
                    <td> 
                    <a href={result} target="_blank" rel="noopener noreferrer">
                        <Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button>  
                        </a>
                        </td>
                </tr>
            </tbody> 
        </Table>   

        <Row>  
            <h2>STAFF (TEACHING)</h2> 
        </Row> 

        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>INFORMATION</th>
                    <th>DETAILS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>PRINCIPAL*	</td>
                    <td>01</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>  
                    <tr>  TOTAL NO. OF TEACHERS* </tr> 
                    <tr>  PGT*	  </tr>
                    <tr>   TGT*	 </tr> 
                    <tr>   PRT*		 </tr>
                       </td>
                    <td>    

<tr>  26</tr> 
                    <tr>  11 </tr>
                    <tr>   5  </tr> 
                    <tr>   10  </tr>
                    </td>
                </tr>
                <tr>
                    <td>3</td>
                    <td> TEACHERS SECTION RATIO*</td>
                    <td> 1.5</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>DETAILS OF SPECIAL EDUCATOR*</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>DETAILS OF COUNSELLOR AND WELLNESS TEACHER*</td>
                    <td>1</td>
                </tr>
            </tbody> 
        </Table>  

          <h2> RESULT CLASS : X</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>YEAR</th>
                    <th>NO. OF REGISTERED STUDENTS</th> 
                    <th>NO. OF STUDENTS PASSED</th> 
                    <th>PASS PERCENTAGE	</th> 
                    <th>REMARKS	</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td> 2021</td>
                    <td>55	</td> 
                    <td>55		</td>
                    <td> 100%</td> 
                    <td> Excellent</td>
                </tr> 
                <tr>
                    <td>2</td>
                    <td> 2022</td>
                    <td>42	</td> 
                    <td>42		</td>
                    <td> 100%</td> 
                    <td> Excellent</td>
                </tr> 
                <tr>
                    <td>3</td>
                    <td> 2023</td>
                    <td>54</td> 
                    <td>54</td>
                    <td> 100%</td> 
                    <td> Excellent</td>
                </tr>
            </tbody> 
        </Table>  

        {/* <h2> RESULT CLASS : XII</h2>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>YEAR</th>
                    <th>NO. OF REGISTERED STUDENTS</th> 
                    <th>NO. OF STUDENTS PASSED</th> 
                    <th>PASS PERCENTAGE	</th> 
                    <th>REMARKS	</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td> 2022-2</td>
                    <td>22	</td> 
                    <td>22		</td>
                    <td> 100%</td> 
                    <td> Excellent</td>
                </tr>
            </tbody> 
        </Table>   */}


        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th> INFORMATION	</th>
                    <th> DETAILS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)*	</td>
                    <td>3788.90 Sq. Mtr.</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td> NO. AND SIZE OF THE CLASS ROOMS (IN SQ MTR)*	</td>
                    <td> 20 - 56 SQ MTR, 21 - 47 SQ MTR</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS(IN SQ MTR)*</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>INTERNET FACILITY*	</td>
                    <td>Yes</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td> NO. OF GIRLS TOILETS*	</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td> NO. OF BOYS TOILETS*	</td>
                    <td>10</td>
                </tr>
                {/* <tr>
                    <td>7</td>
                    <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL	
</td>
                    <td><Button className='p-1 btn btn-outline-success' variant=""> View <i className="bi bi-file-pdf  text-danger"></i></Button></td>
                </tr> */}
              
               
            </tbody> 
        </Table> 
        </Row> 
    </Container> 
</section>   
<Footer/>
      </>
  )
}

export default Admission
