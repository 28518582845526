import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
  postApiFile,
  postApiMultipleFile,
} from "../helpers/apiStructure";
import { baseUrl } from "../const";

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
// banner Mgt
export const getBanners = createAsyncThunk("getBanners", async (body) => {
  const result = await postApi(`${baseUrl}/api/banners`, "post", body);
  return result;
});
export const addBanner = createAsyncThunk("addBanner", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/banner/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteBanner = createAsyncThunk("deleteBanner", async (data) => {
  const result = await postApi(`${baseUrl}/api/banner/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
// Slc Mgt
export const getSlc = createAsyncThunk("getSlc", async (body) => {
  const result = await postApi(`${baseUrl}/api/slc/list`, "post", body);
  return result;
});
export const addSlc = createAsyncThunk("addSlc", async (body) => {
  const result = await postApiMultipleFile(`${baseUrl}/api/slc/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSlc = createAsyncThunk("updateSlc", async (body) => {
  const result = await postApiMultipleFile(`${baseUrl}/api/slc/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteSlc = createAsyncThunk("deleteSlc", async (data) => {
  const result = await postApi(`${baseUrl}/api/slc/remove/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
// Gallery Mgt
export const getGallery = createAsyncThunk("getGallery", async (body) => {
  const result = await postApi(`${baseUrl}/api/gallery/images`, "post", body);
  return result;
});
export const addGallery = createAsyncThunk("addGallery", async (body) => {
  const result = await postApiMultipleFile(`${baseUrl}/api/gallery/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteGallery = createAsyncThunk("deleteGallery", async (data) => {
  const result = await postApi(`${baseUrl}/api/gallery/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
//contact mail send
export const contactSendMail = createAsyncThunk("contactSendMail", async (body) => {
  const result = await postApi(`${baseUrl}/api/sendemail`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

const userReducer = createReducer(
  {

  },
  (builder) => {
    builder
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
          localStorage.setItem("userid", action.payload?.me?.id);
        }
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      // banner list
      .addCase(getBanners.fulfilled, (state, action) => {
        state.getBannerData = action.payload?.list;
      })
      // Slc list
      .addCase(getSlc.fulfilled, (state, action) => {
        state.getSlcList = action.payload?.list;
      })
      //Gallery list
      .addCase(getGallery.fulfilled, (state, action) => {
        state.getGalleryData = action.payload?.list;
      })
  }
);

export default userReducer;
