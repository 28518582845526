import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addSlc, deleteSlc, getBanners, getSlc, updateSlc } from "../../reducers/commonReducer";
import { Button } from "react-bootstrap";
import { baseUrl } from "../../const";
import SlcAction from "./SlcAction";

const SlcList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getSlc())
  }, []);
  const { getSlcList } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <SlcAction title={"Add"} api={addSlc}/>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>SLC No.</th>
                <th>Name</th>
                <th>Father Name</th>
                <th>Date</th>
                <th>View SLC</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getSlcList &&
                getSlcList?.map((data, index) => {
                  return (
                    <tr key={data.id}>
                      <td>{index + 1}.</td>
                      <td>{data?.slcNo}</td>
                      <td>{data?.name}</td>
                      <td>{data?.fatherName}</td>
                      <td>{moment(data?.createdAt).format("MMM Do YY")}</td>
                      <td className="table-img">
                      <Button onClick={() => window.open(`${baseUrl}/${data?.slc}`, '_blank')} alt={data?.name}>View</Button>
                      </td>
                      <td>
                        {/* <SlcAction title={"Edit"} api={updateSlc} name={data?.name} slcNo={data?.slcNo} fatherName={data?.fatherName} id={data?.id}/> */}
                        <Button variant="danger" onClick={async() =>{
                          const userConfirmed = window.confirm('Are you sure you want to perform this action?');
                          if(userConfirmed){
                            await dispatch(deleteSlc(data?.id))
                            await dispatch(getSlc());
                          }
                        }}><i className="bi bi-trash-fill"></i></Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default SlcList;
