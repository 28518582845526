import React from 'react' ; 
import { Container, Row, Col } from 'react-bootstrap'; 
import WebHeader from '../Component/WebHeader'; 
import Button from 'react-bootstrap/Button';  
import contactImg from "../Images/contact/contact-img.png"; 
import Form from 'react-bootstrap/Form'; 
import FloatingLabel from 'react-bootstrap/FloatingLabel'; 
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Footer from '../Component/Footer'; 
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { adminLogin, contactSendMail } from "../../reducers/commonReducer";
import {motion} from "framer-motion" ; 


const schema = yup.object().shape({
  name: yup.string().required("Enter Name"),
  email: yup.string().required("Enter your email."),
  phone: yup.number().required("Enter your phone."),
  address: yup.string().required("Enter your address."),
  message: yup.string().required("Enter your Query."),
});

const ContactUs = () => {  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let width = window.innerWidth;

  return (
    <> 
<motion.div  
  initial = {{opacity:0}} 
  animate = {{opacity:1}} 
  exit={ {opacity:0}} 
  transition={ { duration : 2}}

  >
    <WebHeader/>
   <section className='about-us Gallery-section'> 
    <Container>
      <Row className='text-center pt-5 pb-5'>
        <Col className='text-white fs-1 fw-bold'> <p>Contact Us </p></Col>
      </Row> 
    </Container> 
    </section>   
    <section>  
    <div className="address-section pt-5">
      <Container>
        <Row>
          <Col md={3}>
          <div className="d-flex flex-row mb-3 align-items-center">
            <div className="p-2"><i className="bi bi-telephone-forward fs-1"></i></div>
            <div className="p-2"> 
            <h3>Office</h3>  
            <p>+91 8222999891</p>
            </div>
          </div>
            </Col>
            <Col md={3}>
            <div className="d-flex flex-row mb-3 align-items-center">
            <div className="p-2"><i className="bi bi-telephone-forward fs-1"></i></div>
            <div className="p-2"> <h3>Call Us</h3>  
            <p>+91 8222999892</p>
            </div>
          </div>
          </Col>
          <Col md={3}>
          <div className="d-flex flex-row mb-3 align-items-center">
            <div className="p-2 me-3"><i className="bi bi-telephone-forward fs-1"></i></div>
            <div className="p-2"> <h3>Message Us</h3>  
            <p> +91 8222999893</p>
            </div>
          </div>
          </Col> 
          <Col md={3}>
          <div className="d-flex flex-row mb-3 align-items-center">
            <div className="p-2 me-3"><i className="bi bi-telephone-forward fs-1"></i></div>
            <div className="p-2"> <h3>Reach Us</h3>  
            <p> +91 8222999894</p>
            </div>
          </div>
          </Col>
        </Row> 
      </Container>
    </div>
    </section> 
    <section className='map-section mt-4 mb-5'> 
    <Container>  
      <Row>  
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.71868170611!2d75.65117117431465!3d29.11398556205689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3913cc703963db7f%3A0x2cb54b3f03919b57!2sGlobal%20International%20School%20Arya%20Nagar%20(Hisar)!5e0!3m2!1sen!2sin!4v1703157881140!5m2!1sen!2sin"  width="100%"
          height="400"
          style={{ border: 0 }}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Row>
    </Container> 
    </section> 
   <section className='mb-5'>  
    <Container> 
       <h5> CONTACT US </h5> 
       <h2 className='fs-1 fw-bold'> Join Our Best Fun Classes </h2> 
     
        <div className="row pt-3 pb-3"> 
          <Row>  
             <Col md={6}>  
        <img src={contactImg} className=''/>
         </Col>  
          <Col md={6}>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                dispatch(contactSendMail(values))
                resetForm({ values: "" });
              }}
              initialValues={{
                name:"",
                email:'',
                phone:'',
                address:'',
                message:''
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12} className='mb-2'>
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className='mb-2'>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-envelope-check-fill"></i>
                          </div>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className="form-control"
                            placeholder="Email"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-2'>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-telephone-fill"></i>
                          </div>
                          <Form.Control
                            type="number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                            className="form-control"
                            placeholder="Phone"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className='mb-2'>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="address"
                            as="textarea"
                            row={3}
                            value={values.address}
                            onChange={handleChange}
                            isInvalid={!!errors.address}
                            className="form-control"
                            placeholder="Address"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='mb-2'>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-pen-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="message"
                            as="textarea"
                            row={3}
                            value={values.message}
                            onChange={handleChange}
                            isInvalid={!!errors.message}
                            className="form-control"
                            placeholder="Write your query...."
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="col-12 text-center mt-4">
                    <Button
                      type="submit"
                      className="button-color login-btn px-4 w-100"
                    >
                      Contact Us
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col> 
          </Row>
        </div>
    </Container>
   </section> 
   <Footer/> 
  </motion.div>  
    </>
  )
}

export default ContactUs
