import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addBanner, getBanners } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  image: yup.mixed().required("Please Upload Image."),
  mblimg: yup.mixed().required("Please Upload Mobile Image."),
});

function BannerAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
       Add Banner
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(addBanner(values));
            resetForm({ values: "" });
            dispatch(getBanners())
            setShow(false)
          }}
          initialValues={{
            name:'',
            image:'',
            mblimg:'',
            orderby:''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                  <Col md={6} className="mb-1">
                    <Form.Group>
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const img = event.target.files[0];
                          setFieldValue("image", img);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-1">
                    <Form.Group>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="mblimg"
                        onChange={(event) => {
                          const mblimg = event.target.files[0];
                          setFieldValue("mblimg", mblimg);
                        }}
                        isInvalid={!!errors.mblimg}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mblimg}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>OrderBy</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Banner OrderBy"
                      name="orderby"
                      value={values.orderby}
                      onChange={handleChange}
                      isInvalid={!!errors.orderby}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.orderby}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default BannerAction;
