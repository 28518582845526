import React from 'react' ; 
import { Container, Row, Col } from 'react-bootstrap'; 
import WebHeader from '../Component/WebHeader'; 
import Button from 'react-bootstrap/Button'; 
import aboutone from '../Images/about/about.jpg'; 
import aboutwo from '../Images/about/06.jpg';
import Footer from '../Component/Footer'; 
import Icon1 from "../Images/about/icon/icon5.png" ; 
import Icon2 from "../Images/about/icon/icon7.png" ;
import Icon3 from "../Images/about/icon/icon8.png" ;
import Icon4 from "../Images/about/icon/icon9.png" ; 
import { Link } from 'react-router-dom'; 
import {motion} from "framer-motion" ; 

const AboutUs = () => {
  return (
    <>  

      <motion.div  
    initial = {{opacity:0}} 
    animate = {{opacity:1}} 
    exit={ {opacity:0}} 
    transition={ { duration : 2}}

    >    
      <WebHeader/>
   <section className='about-us Gallery-section'> 
    <Container>
      <Row className='text-center pt-5 pb-5'>
        <Col className='text-white fs-1 fw-bold'> <p> About Us </p></Col>
      </Row>
    </Container> 
    </section> 

    <section>  

        <Container> 
       
   
        <Row className='pt-5 pb-5 align-items-center'> 
         
       
        <Col md={6} lg={6}>  
        
       <h2 className='fs-1 fw-bold pb-4'> <span className='welcome'>WELCOME TO </span> 
 <span className='bg-blue'> GLOBAL INTERNATIONAL SCHOOL </span> </h2> 
 <p className='pb-4'> To build a rich intellectual potential extended with inter disciplinary 
knowledge, human values and professional ethics among the 
student so that they contribute to society and create a niche for a 
successful career and in the approaches employed to realize 
individual and collective aspiration.</p>   

<Link to="/contact">  
          <motion.div  
 initial = {{opacity:0, scale: 0 ,  x:500}} 
 whileInView={{opacity:1, scale:1,  x: 0 }} 
 transition={{duration :0.9}}
 
 >  
          
           <p className='pt-2'> 
          
           <Button className='p p-3' variant="primary">Contact Us <i className="bi bi-arrow-right"></i></Button></p>  
           </motion.div> 
           
            </Link>
        </Col> 
       

        <Col md={6} lg={6}> 
            <img className='w-100' src={aboutone} /> 
           
      
        </Col>  
 
        <Col md={6} lg={6}> 
            <img className='w-100' src={aboutwo} />
      
        </Col> 

        <Col md={6} lg={6}> 
       <h2 className='fs-1 fw-bold pb-4'>Our Mission </h2> 
 <p className='pb-4'>  Praesent rhoncus justo erat, sed sollicitudin arcu malesuada vel. Etiam scelerisque justo ut purus luctus ullamcorper. Vivamus vitae elit ligula. Fusce eu rutrum nisl. </p>    
 <ul className='school-list '> 
    <li><i class="bi bi-arrow-right"></i> To Provide the finest quality education that will evolve in step 
with the changes that takes place globally</li> 
    <li> <i class="bi bi-arrow-right"></i> To Provide an environment conducive to the development of 
progressive thinking to set new qualitative standards in 
education by employing innovative approaches</li>
    <li> <i class="bi bi-arrow-right"></i> To mould young learners in to responsible, wise and balanced 
human beings to face the challenges of ever changing society 
and make them competent in their life</li>
 </ul>

  <Link to="/contact">  <p className='pt-5'> <Button className='p p-3' variant="primary">Contact Us <i className="bi bi-arrow-right"></i></Button></p>  </Link>

        </Col>  
      </Row>     
    

      <section className='mt-5 mb-5'>   

    <Container className='admisson-procedure p-5'> 
<Row> 
 
  <h2>Procedure of Admission</h2> 
  <p> For Pre-School (Nursery) Admission is on "First Come First Served' Basis.Admission to class I to IX is on merit basis. (Written
Entrance Test). No admission is invited to std. X. Admissions are accepted in accordance to the vacancies in a class.
</p>  
<h3> School reserves the right to admission </h3>  
<h2> DOCUMENTS REQUIRED AT THE TIME OF ADMISSION </h2>  
<p> Photocopy of aadhar card ofthe students.•Proof of date of birth and school leaving certicate ofthe last school attended. •
TwoPass-Port sizePhotographs ofthe pupil and one photo of each of parents.•Photocopy ofmark sheet oflast class pass.</p> 

<p> 
In order to inculcate the spirit of social equality and togetherness and to instill a feeling of specic common identity among 
pupils coming from different background and socio-economic strata, the school has made wearing of uniform mandatory for 
all the pupils (Monday to Saturday). The pupils will wear their "Valley Dress" on Wednesday and saturday in summer & school 
track suit in winter.
</p> 
<p> Note : 1. All pupils are expected to maintain the dignity and sobriety of the school uniform/Valley Dress/ Track Suit.
 2. The samples of school uniform /Valley Dresses/Track suits are put on display at the school reception.
VindhayanchalValley- GreenColour|GoverdhanValley-YellowColour|KailashValley-RedColour|HimalayaValley-BlueColour</p> 
<h2> Transport</h2> 
<p> We have a Fleet of vans and buses for transporting students from various locations to school and back</p> 
<h2>  Parents Teacher Meet (ptm)
</h2> 
<p> Parents Teacher Meets will be organized on monthly basis. It gives an opportunity to the parents/guardians and 
teachers to discuss, in person, the progress of their ward. Suggestions and ideas are welcomed from parents community to 
have academic excellence of their ward</p> 
<h2> Parents Help For Betterment Of Their Ward</h2> 
<p> See your ward is regular to school • See he/she completes school Home work Daily. • Make him/her ready before 
school time • Check students diary daily • In case you take your ward home during school timing, come personally or send 
authority letter through your representative • See that your ward's hair and nails are timely cut.</p>




</Row>  
</Container> 

    </section>

      <Row className='mt-5 mb-5'>
        <Col xs={12} md={6}>
          <h2 className='fs-1 fw-bold pb-2'>Our Benefits</h2>
          <p> The students of global international are encouraged to develop the 
habits of mind in a joyful learning expansive Here at Global International 
School curriculum can be demanding but the culture of the school is also 
caring and supportive. The atmosphere is one of cooperation and a 
shared endeavor in the pursuit of learning and growth. Extra help outside 
the classroom is an important element of the school culture as teachers 
recognise their students learn best when they can trust in their teacher's 
kindness and understanding and when the classroom is place for all 
questions and ideas.</p>
          <Link to="/contact">  
          <motion.div  
 initial = {{opacity:0, scale: 0 ,  x:500}} 
 whileInView={{opacity:1, scale:1,  x: 0 }} 
 transition={{duration :0.9}}
 
 >  
          
           <p className='pt-2'> 
          
           <Button className='p p-3' variant="primary">Contact Us <i className="bi bi-arrow-right"></i></Button></p>  
           </motion.div> 
           
            </Link>
        </Col>

        {/* Second Column with 4 sub-columns */}
        <Col xs={12} md={6}> 
        <h2> PE & Sports</h2>
        <h4>Aspire to your Personal Best </h4> 
        <p> At Global International School we believe PE & School Sport plays an 
important role in making our PE vision statement a reality for every pupil, 
with the potential to change young people’s lives for the better. 
Our provision falls under the following three main headings: </p>
          <Row>
            {/* First Sub-Column */}
            <Col xs={6} md={6}>  
            <img src={Icon1} alt="Image 1" className="img-fluid" />
            <h5 className='pt-3 fw-bold'> Physical Education </h5>  
              <p>Raising standards for all our children in Physical 
Education </p>
            </Col>

            {/* Second Sub-Column */}
            <Col xs={6} md={6}>
              <img src={Icon2} alt="Image 2" className="img-fluid" /> 
              <h5 className='pt-3 fw-bold'> Healthy Active Lifestyles</h5>
              <p>Ensuring all our children have access to 
regular exercise.</p>
            </Col>

            {/* Third Sub-Column */}
            <Col xs={6} md={6}>  
            <img src={Icon3} alt="Image 3" className="img-fluid" />
            <h5 className='pt-3 fw-bold'> Competitive School Sport</h5>
              <p>  Increasing pupils’ participation in extra curricular sport through ‘School Games’ competitions.</p>
            </Col>

            {/* Fourth Sub-Column */}
            <Col xs={6} md={6}>  
            <img src={Icon4} alt="Image 4" className="img-fluid" />
            <h5 className='pt-3 fw-bold'> Summer Camp </h5>
              <p> Summer camp is a supervised program for children or teenagers 
conducted during the summer months. </p>
            </Col>
          </Row>
        </Col>
      </Row>  




        </Container> 
    </section> 
    <Footer/> 
    </motion.div>
      
    </>
  )
}

export default AboutUs
