import React from 'react' ;
import {motion} from "framer-motion" ; 

const CompOne = (props) => {
    const {compOneimg, compOneh3,compoparagrap,compOneclass,imageshape} = props
  return (
    <> 
    
       <div className={compOneclass}> 
      
            <img src={compOneimg} alt="Image 1" className={`mg-fluid ${imageshape}`} /> 
          
            <h3 className='mt-4'>{compOneh3}</h3> 
            <motion.div  
 initial = {{opacity:0, scale: 0 ,  y:-500}} 
 whileInView={{opacity:1, scale:1,  y: 0 }} 
 transition={{duration :0.9}}
 
 >
          <p>
              {compoparagrap}
            </p>  
            </motion.div>
        
          </div>  

       

        

    </>
  )
}

export default CompOne
