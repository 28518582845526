import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import WebHeader from '../Component/WebHeader';
import Footer from '../Component/Footer'; 
import { useDispatch, useSelector } from 'react-redux';
import { getGallery } from '../../reducers/commonReducer';

const Gallery = () => { 
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); 
  useEffect(() => {
    dispatch(getGallery())
  },[])
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };
  const { getGalleryData } = useSelector((state) => state.commonReducer);
  return (
    <> 
      <WebHeader/> 
      <section className='about-us Gallery-section'> 
    <Container>
      <Row className='text-center  pt-5 pb-5'>
        <Col className='text-white fs-1 fw-bold'> <p> Gallery </p></Col>
      </Row>
    </Container> 
    </section> 
      <Container className='mt-3 mb-3'>
      <Row className='m-auto'>
          {!!getGalleryData &&
            getGalleryData?.map((data, index) => (
              <Col key={index} xs={4} md={4} lg={4} className='p-2 text-center'>
                <div className="image-container">
                  <img
                    src={data?.image}
                    alt={`Image ${index + 1}`}
                    className="img-fluid"
                    onClick={() => handleImageClick(data?.image)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </Col>
            ))}
        </Row>

        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg"
       dialogClassName="modal-90w"
       aria-labelledby="example-custom-modal-styling-title"
      centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Full Screen"
                className="img-fluid"
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container> 

      <Footer/>
    </>
  )
}

export default Gallery;
