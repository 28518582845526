import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { deleteGallery, getGallery } from "../../reducers/commonReducer";
import BannerAction from "./GalleryAction";
import { Button } from "react-bootstrap";
import { baseUrl } from "../../const";

const GalleryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGallery());
  }, []);
  const { getGalleryData } = useSelector((state) => state.commonReducer);
  
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Gallery <span className="text-danger">(Please don't add unnecessary images.)</span></h3>
          </Col>
          <hr />
          <Col md={6}>
            <BannerAction/>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Name</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getGalleryData &&
                getGalleryData?.map((data, index) => {
                  return (
                    <tr key={data.id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td className="table-img">
                      <img onClick={() => window.open(`${baseUrl}/${data?.image}`, '_blank')} src={data?.image} alt={data?.name}/>
                      </td>
                      <td>
                        <Button variant="danger" onClick={async() =>{
                          const userConfirmed = window.confirm('Are you sure you want to perform this action?');
                          if(userConfirmed){
                            await dispatch(deleteGallery(data?.id))
                            await dispatch(getGallery());
                          }
                        }}><i className="bi bi-trash-fill"></i></Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default GalleryList;
