import React from "react";
import "./app.css";
import "./Website/WebCss/web.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import CategoryList from "./Pages/Banners/BannerList";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { Row } from "react-bootstrap";
import Homepage from "./Website/Pages/Homepage";
import AboutUs from "./Website/Pages/AboutUs";
import ContactUs from "./Website/Pages/ContactUs";
import Gallery from "./Website/Pages/Gallery";
import GalleryList from "./Pages/Gallery/GalleryList";
import ScrollTop from "./Website/Component/ScrollTop";
import Admission from "./Website/Pages/Admission";
import AdmissionProcedure from "./Website/Pages/AdmissionProcedure";
import SlcList from "./Pages/SLC/SlcList";
import SLC from "./Website/Pages/SLC";

function App() {
  return (
    <>
     <Row className="p-0 m-0 translater-tab">
     <div id="google_translate_element"></div>
     </Row> 
     <ScrollTop/>
      <Routes>
        {/* web routes start*/} 
        <Route path="/"  element={<Homepage/>}  />  
        <Route path="/about"  element={<AboutUs/>}  />  
        <Route path="/slc-list"  element={<SLC/>}  />  
        <Route path="/contact"  element={<ContactUs/>}  />  
        <Route path="/gallery"  element={<Gallery/>}  />  
        <Route path="/cbse-mandatory-disclosure"  element={<Admission/>}  /> 
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/banner" element={<CategoryList />} />
        <Route path="/slc" element={<SlcList />} />
        <Route path="/gallery-img" element={<GalleryList />} /> 
        <Route path="/admisson-procedure" element={<AdmissionProcedure/> } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
export default App;
