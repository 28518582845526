import React from 'react' 
import { Container, Row, Col, NavLink } from 'react-bootstrap'; 
import logo from "../Images/logo/logo.png" 
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
   <footer className="bg-dark text-light pt-5 pb-5">
        <Container>
          <Row className='align-items-baseline'>
            <Col xs={12} md={6} lg={3} className=" text-md-left">
              <img src={logo} className='mb-4' alt="Logo" style={{ maxWidth: '100%', maxHeight: '80px' }} /> 
              <h6 className='fs-5 fw-bold'>Global International School </h6>
              <p>An English Medium School (Affilated to CBSE) Affilation Number - 531241</p>
            </Col>
            <Col xs={12} md={6} lg={4} className=" text-md-left">
              <h5  className='mb-4'>Contact Info</h5>
              <ul className='address-list web-footer-link'>
                <li> <a href="tel:+91 8222999891"> <i className="bi bi-telephone me-2"></i>+91 8222999891 , </a>  
                <span className=''>  
                <a href="tel:+91 8222999892">+91 8222999892 </a> 
                  </span> 
               
                 </li> 
                <li> <a href="tel:+91 8222999893"> <i className="bi bi-phone me-2"></i>+91 8222999893 , </a> 
                <span className=''>  
                <a href="tel:+91 8222999894">+91 8222999894 </a> 
                  </span>   
                 </li>
                <li><i className="bi bi-geo-alt me-2"></i> Arya Nagar, Hisar (Haryana)</li>
                <li><a href = "mailto:globalinternationalschool01@gmail.com"><i className="bi bi-envelope me-2"></i> globalinternationalschool01@gmail.com </a></li>
              </ul>
            </Col>
            <Col xs={12} md={6} lg={3} className=" text-md-left">
              <h5  className='mb-4'>Quick Links</h5>
              <ul className='web-footer-link'>
                <li> <Link to='/' > <i className="bi bi-caret-right fs-6 me-2"></i> Home </Link> </li>
                <li> <Link to='/about' ><i className="bi bi-caret-right fs-6 me-2"></i>About Us </Link>  </li>
                <li> <Link to='/contact'><i className="bi bi-caret-right fs-6 me-2"></i>Contact Us </Link></li>
                <li> <Link to='/gallery'> <i className="bi bi-caret-right fs-6 me-2"></i>Gallery </Link> </li>
                <li> <Link to='/slc-list'> <i className="bi bi-caret-right fs-6 me-2"></i>Download SLC </Link> </li>
              </ul>
            </Col>
            <Col xs={12} md={6} lg={2} className="text-md-left">
              <h5  className='mb-4'>Information</h5>
              <ul className='address-list d-flex text-center'>
                <li> <i class="bi bi-facebook me-3 fs-5"></i> </li> 
                <li><i className="bi bi-youtube me-3 fs-5"></i></li>
                <li><i className="bi bi-instagram me-3 fs-5"></i> </li>
              </ul>
            </Col>
          </Row> 

          <hr/> 
          <Row className='text-center'> 
            <p> Copyright ©{new Date().getFullYear()} Global International School. All Rights Reserved. Created with ♥ by 
              <NavLink target='#' className='d-inline' href='https://www.havfly.com/'>  Havfly </NavLink></p>
          </Row>
        </Container>
      </footer>
      
    </>
  )
}

export default Footer
