import React from 'react' ;
import { Container, Row, Col } from 'react-bootstrap'; 
import Button from 'react-bootstrap/Button';
import WebHeader from '../Component/WebHeader' 
import labone from "../Images/lab/creative-knowledge-lightbulb-svgrepo-com.svg" ; 
import labtwo from "../Images/lab/education-laboratory-school-2-svgrepo-com.svg" ; 
import labthree from "../Images/lab/blue-book-svgrepo-com.svg";  
import labtfour from "../Images/lab/weight-silhouette-for-medical-sport-practice-svgrepo-com.svg" ;  
import CompOne from '../Component/CompOne';
import CompTwo from '../Component/CompTwo';
import Slider from '../Component/Slider'; 
import Section from "../Images/sectionone/image-018.png" ;
import TeamSlider from '../Component/TeamSlider';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom'; 
import {motion} from "framer-motion" ; 

const Homepage = () => {
  return (
    <>  
    <motion.div  
    initial = {{opacity:0}} 
    animate = {{opacity:1}} 
    exit={ {opacity:0}} 
    transition={ { duration : 2}}
    > 
     <WebHeader/>  
     <Slider/>  
     <Container> 
      <Row className='pt-5 mt-5 align-items-center' >
      <Col md={8} className="mb-2">   
      <motion.div   
      initial = {{x:-1000}} 
      animate = {{ x:[900,0]}}        
      transition={{
        duration : "2", 
        delay : "1"
      }}
      > 
       <h2 className='fs-1 fw-bold pb-2'> <span className='welcome'>WELCOME TO </span> 
 <span className='bg-blue'> GLOBAL INTERNATIONAL SCHOOL </span> </h2>    
 </motion.div> 

 <motion.div  
 initial = {{opacity:0, scale: 0 ,  y:-500}} 
 whileInView={{opacity:1, scale:1,  y: 0 }} 
 transition={{duration :0.9}}
 
 > 

 <p> Educational institutions play a foremost role in the development and
modernization of a nation. To add a drop to the ocean of education, we 
have set up our institution to provide quality education for all round 
development of student in the area.</p>
      <p> Welcome to Global International School a highly progressive 
international school in Arya Nagar Pledged to provide quality education 
with great emphasis on traditional values.</p>   
<h2 className='fw-bold fs-2'> Aims & Objectives</h2>
      <ul className='school-list'> 
        <li> <i className="bi bi-circle-fill text-warning me-2"></i> To enhance language ability by using language skills. </li>
        <li> <i className="bi bi-circle-fill text-danger me-2"></i>  To help students in their social development.</li>  
        <li> <i className="bi bi-circle-fill text-info me-2"></i> To facilitate development of cognitive skills.
</li>  


<li> <i className="bi bi-circle-fill text-warning me-2"></i> To initiate the child towards aesthetic appreciation </li> 
        <li> <i className="bi bi-circle-fill text-primary me-2"></i> To make the students feel wanted & loved in order of fullfill their emotional needs. </li>   

        <li> <i className="bi bi-circle-fill text-primary me-2"></i> To give the children a wealth of experience to understand the world around them. </li> 


        <li> <i className="bi bi-circle-fill text-info me-2"></i> To foster independence and self-reliance in daily activities in order to 
promote free growth of personality.
</li>
        

 
        </ul>   

        </motion.div>
        <Link to="/about">   <p className='mt-4'>  <Button className='border p-3' variant="primary">Find Out More <i className="bi bi-arrow-right"></i></Button></p>  </Link>
        
       </Col> 
      <Col md={4} className="mb-2">  
      <img src={Section} alt="Image" className="img-fluid rounded-circle" /> 
   
       </Col>
      {/* <Col md={4} className="mb-2">  
      <p> <span className='fs-1 fw-bold'> A</span>re you look at schools, ask about the kinds of studies that they offer. Some high schools are academic, aimed at teaching children who plan to attend college.</p>  
      <p> If you are a newbie to managing a WordPress website, then congratulations! You are here at the right track with  us because we are going to introduce you. You are here at the right track with  us because we are going to introduce you.One of the most basic.</p> 
      <img src={Sigin} />
      </Col> */}
      </Row>
    </Container>  

     <Container className='mt-5 mb-5'> 
     <p className='text-center fs-2 fw-bold'> The day starts with the morning assembly which is conducted by each class on weekly basis. It includes ...</p> 
   
      <Row className='pt-5 pb-5 main-motive'> 
   
        <Col md={6} lg={3}> 
        <CompOne compOneclass = "text-center" compOneimg = {labone} compOneh3 = "General Awareness"  compoparagrap = "Our General Awareness program aims to instill a sense of curiosity and awareness in children, fostering a lifelong love for learning beyond the classroom" />
        </Col>
        <Col md={6} lg={3}>
        <CompOne compOneclass = "text-center" compOneimg = {labthree} compOneh3 = "Educative topic"  compoparagrap = "Education is a lifelong journey that extends far beyond the confines of traditional classrooms. In a rapidly changing world, adaptability is a key trait." /> 
        </Col>

        <Col md={6} lg={3}>  
        <CompOne compOneclass = "text-center" compOneimg = {labtwo} compOneh3 = "Moral values"  compoparagrap = "Moral values form the foundation of a just and harmonious society, guiding individuals in their interactions and shaping the collective conscience" />
        </Col>

        <Col md={6} lg={3}> 
        <CompOne compOneclass = "text-center" compOneimg = {labtfour} compOneh3 = "Mass PT"  compoparagrap = "Physical training is a broad term that encompasses various forms of exercise and activities designed to improve or maintain physical fitness like Running" />
        </Col>
      </Row>  

    </Container>  
      

    <div className="background-section">
      <Container>
        <Row className='align-items-center'>
          <Col md={12} className="text-white">
            <h2 className='mb-5 fs-1 pt-5'>Activity Based Learning Programmes</h2>
            <Row>
            <Col md={6} className="mb-2"> 
            <CompTwo
        CompTwoIcon="bi-hourglass"
        CompTwoHeading="Language Lab"
        CompTwoParagraph="We are glad to announce that Global International School is the rest school of the area which is equipped with system of 
        teaching by language labs."
      />
    </Col>
    <Col md={6} className="mb-2"> 
    <CompTwo
        CompTwoIcon="bi-smartwatch"
        CompTwoHeading="Smartclass Technology"
        CompTwoParagraph="The Smartclass is a comprehensive technology solution that is transforming theway teachers teach in class rooms across the globe today."
      />
    </Col>
    <Col md={6} className="mb-2">
    <CompTwo
        CompTwoIcon="bi-book"
        CompTwoHeading="School Library"
        CompTwoParagraph="Books are best companions of human. To develop more friendship with books among students a well stacked library having good books of all taste for every student."
      />
    </Col>
    <Col md={6} className="mb-2">
    <CompTwo
        CompTwoIcon="bi-tools"
        CompTwoHeading="Science Lab"
        CompTwoParagraph="A well stocked science laboratory is provided in the school. The lab is full of models and charts that enables the students to 
        experience the joy of discovery in a practical way."
      />
    </Col>
    <Col md={6} className="mb-2">
    <CompTwo
        CompTwoIcon="bi-flower2"
        CompTwoHeading="Co-Curricular Activities"
        CompTwoParagraph="The cultural activities at Global International School are considered to be an effective means of education, training and learning and school wishes the students to be earnest about these activities."
      />
    </Col>
    <Col md={6} className="mb-2">
    <CompTwo
        CompTwoIcon="bi-shop-window"
        CompTwoHeading="Inter-Valley Activities"
        CompTwoParagraph="The school has four valleys. The valleys are headed by Chief Animators from the educators' side and by the Captains and 
        Vice-Captains from the students side."
      />
    </Col>
            </Row>
          </Col> 
           {/* Column with an image */}
           {/* <Col md={5}>
            <img src={parllex} alt="Your Image" className="img-fluid" />
          </Col> */}
        </Row>
      </Container>
    </div> 


   <div className='bg-primary-subtle pt-5 pb-5 team-slider mb-5'> 
    <Container  className='mt-5 text-center'>
      <TeamSlider/>
    </Container> 
    </div> 

    <div className="parent-section pt-5 pb-5">
  <div className="color-layer layer1"></div>
  <Container className="d-flex align-items-center justify-content-center">
    <Row className='text-white'>
      <Col xs={12} className="text-center"> 
        <div className='staff-member'> 
          <div className='text-content'> 
          <motion.div  
    initial = {{opacity:0, y:-500}} 
    whileInView={{opacity:1, y: 0 }} 
    transition={ { duration : 2}}

    > 
            <h2>Staff Members</h2>
            <p>The secret of the success of Global International School is its staff. They are professionally competent, hardworking, and committed to their work. They teach the children to dream and turn them into reality. We encourage them to be the best in whatever they do. We encourage our students to aim high and develop a positive attitude towards their work and life. We always provide our children a family atmosphere and motherly care.</p> 
            <p>We provide our faculty members with refresher courses and workshops to upgrade their knowledge and skills.</p>
           <Link to='/contact'>  <p><Button className='p p-3' variant="primary">Contact Us <i className="bi bi-arrow-right"></i></Button></p></Link>  
           </motion.div> 
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</div>



    <div className='student'>
      <Container> 
      <motion.div  
    initial = {{opacity:0}} 
    // animate = {{opacity:1}} 
    // exit={ {opacity:0}}  
    whileInView={{opacity:1 }} 
    transition={ { duration : 1}}

    > 
        <Row>
          <Col xs={12} sm={6} md={3} className="text-center">
            <div>
              <h2 className='fw-bold fs-1'>25</h2>
              <p>YEARS OF EXPERIENCE</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className="text-center">
            <div>
              <h2  className='fw-bold fs-1'>12K</h2>
              <p>Total Students</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className="text-center">
            <div>
              <h2  className='fw-bold fs-1'>125</h2>
              <p>DEDICATED TEACHERS</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={3} className="text-center">
            <div >
              <h2  className='fw-bold fs-1'>2530</h2>
              <p>SUBSCRIBERS</p>
            </div>
          </Col>
        </Row> 
        </motion.div> 
      </Container>
    </div>

   <Footer/> 
   </motion.div>


    </>
  )
}

export default Homepage
