import React from 'react';
import { Navbar, Nav, Container, NavLink } from 'react-bootstrap'; 
import logo from "../Images/logo/logo.jpg" ; 
import { Link } from 'react-router-dom';


const WebHeader = () => { 

  return (
    <>
       <Navbar className='mainbg header-fixed' expand="md">
      <Container className=''>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="Logo"
            height="auto" // Adjust the height of the logo as needed
            width="80"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center web-menu">
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link> 
            <Nav.Link href="/admisson-procedure">Admission</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link> 
            <Nav.Link href="/gallery">Gallery</Nav.Link> 
            <Nav.Link href="/cbse-mandatory-disclosure">CBSE MANDATORY DISCLOSURE</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Nav className="ml-auto mobile-hide social-link">
          <Nav.Link href="#facebook">
            <i className="bi bi-facebook"></i>
          </Nav.Link>
          <Nav.Link href="#twitter">
            <i className="bi bi-twitter"></i>
          </Nav.Link>
          <Nav.Link href="#instagram">
            <i className="bi bi-instagram"></i>
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
    </>
  )
}

export default  WebHeader
