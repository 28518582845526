import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addGallery, getGallery } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  images: yup.array().nullable().min(1, 'Please upload at least one image.')
});

function GalleryAction() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
       Add Images
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Gallery Image</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            console.log("values", values)
            await dispatch(addGallery(values));
            resetForm({ values: "" });
            dispatch(getGallery())
            setShow(false)
          }}
          initialValues={{
            name:'',
            images:[],
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              {console.log("images", values?.images)}
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                  <Col md={12} className="mb-1">
                    <Form.Group>
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="images"
                        multiple
                        onChange={(event) => {
                          const imgFiles = event.target.files;
                          setFieldValue('images', imgFiles);
                        }}
                        isInvalid={!!errors.images}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.images}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default GalleryAction;
