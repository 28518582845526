import React from 'react' ; 
import { Container, Row, Col } from 'react-bootstrap'; 
import WebHeader from '../Component/WebHeader' ;
import Footer from '../Component/Footer' ; 
import admisson from "../Images/11.jpg" ;

const AdmissionProcedure = () => {
  return (
    <>
    <WebHeader/>  
    <section className='about-us Gallery-section'> 
    <Container>
      <Row className='text-center pt-5 pb-5'>
        <Col className='text-white fs-1 fw-bold'> <p> ADMISSION PROCEDURE </p></Col>
      </Row>
    </Container> 
    </section>  

    <Container>  
        <Row className='text-center mt-5 mb-5'> <h1 className='fs-1 fw-bold'>ADMISSION PROCEDURE</h1></Row>
        <Row> 
            <Col md={1}>  
            <h2 className='fs-1 fw-bold text-primary'> 0<span className='text-danger'>1.</span> </h2>
            </Col> 
            <Col md={11}>  
            <h3> Registration for admission in the new academic session begins around mid-February.</h3> 
            <ol> 
                <li> For Pre school (Nursery to U.K.G.) Admission is on “First Class First Served” basis.</li> 
                <li>Admission to Class I to IX is on merit basis (Written Entrance Test).</li> 
                <li>Admissions are accepted in accordance to the vacancies in a class.</li> 
                <li>School reserves the right of Admission. </li>
            </ol>
            </Col>

        </Row> 
        <Row className='mt-5 mb-5'> 
            <Col md={1}>  
            <h2 className='fs-1 fw-bold text-primary'> 0<span className='text-danger'>2.</span> </h2>
            </Col> 
            <Col md={11}>  
            <h3> DOCUMENTS REQUIRED AT THE TIME OF ADMISSION.</h3> 
            <ol> 
                <li>Copy of Date of Birth Certificate.</li> 
                <li>Aadhar Card.</li> 
                <li>School Leaving Certificate of the last school attended.</li> 
                <li> Result Report/Marksheet of tha last Exam /class passed. </li> 
                <li>Two latest Passport Size Photographs of the student.</li>
            </ol>
            </Col>

        </Row>
        <Row className='mt-5 mb-5'> 
            <Col md={1}>  
            <h2 className='fs-1 fw-bold text-primary'> 0<span className='text-danger'>3.</span></h2>
            </Col> 
            <Col md={11}>  
            <h3> RULES FOR DEPOSITING FEE.</h3> 
            <ol> 
                <li> Fee is deposited on a monthly basis. Fee is accepted in the month of April (along with the annual charges etc.).</li> 
                <li>The last date to deposit fee for the month is 10th of the month.</li> 
                <li>After 10th, late fee of Rs. 5/- per day will be charged.</li> 
                <li>Fee and other charges once deposited will not be refunded under any circumstances. </li>
            </ol>
            </Col>

        </Row>
    </Container> 
    <Row> 
        <img src={admisson} alt="" />
    </Row>

    <Footer/> 

      
    </>
  )
}

export default AdmissionProcedure
