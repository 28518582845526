import React, { useEffect, useRef } from 'react' ; 
import { Carousel} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../../reducers/commonReducer';


const Slider = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  useEffect(() => {
    dispatch(getBanners())
  },[])
  const { getBannerData } = useSelector((state) => state.commonReducer);
  return (
    <> 
    <div className='main-slide'> 
    <Carousel>
      {
        !!getBannerData && getBannerData?.map((data, index) => {
          return(
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={width?.current < 468 ? data?.mblimg : data?.image}
                alt={data?.name}
              />
              {/* <div className="banner-slide" style={{ backgroundImage: `${data?.image}` }}>
                <Container className="d-flex align-items-center justify-content-center h-100">
                </Container>
              </div> */}
          </Carousel.Item>
          )
        })
      }
    </Carousel> 
       </div>
    
    </>
  )
}

export default Slider
