import React from 'react' 
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop = () => { 
    const { pathname } = useLocation();
    useEffect(() => {
        const scrollToTopRoutes = ['/', '/about', '/contact', '/gallery'];
        if (scrollToTopRoutes.includes(pathname)) {
          window.scrollTo(0, 0);
        }
      }, [pathname]);
    
      return null;
    }

export default ScrollTop
