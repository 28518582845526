import React from 'react' ; 
import { Container} from 'react-bootstrap'; 
import WebHeader from '../Component/WebHeader'; 
import Footer from '../Component/Footer'; 
import { useEffect } from 'react';
import {Table, Button} from "react-bootstrap";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { getSlc } from '../../reducers/commonReducer';
import { baseUrl } from '../../const';

const SLC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSlc())
      }, []);
    const { getSlcList } = useSelector((state) => state.commonReducer);
  return (
    <>  
        <WebHeader/>
            <Container className='mt-4'>
                <Table striped bordered hover size="sm" className="overflow-scroll">
                    <thead>
                    <tr>
                        <th>Sr</th>
                        <th>SLC No.</th>
                        <th>Name</th>
                        <th>Father Name</th>
                        <th>Date</th>
                        <th>View SLC</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!!getSlcList &&
                        getSlcList?.map((data, index) => {
                        return (
                            <tr key={data.id}>
                            <td>{index + 1}.</td>
                            <td>{data?.slcNo}</td>
                            <td>{data?.name}</td>
                            <td>{data?.fatherName}</td>
                            <td>{moment(data?.createdAt).format("MMM Do YY")}</td>
                            <td className="table-img">
                            <Button onClick={() => window.open(`${baseUrl}/${data?.slc}`, '_blank')} alt={data?.name}>View</Button>
                            </td>
                            </tr>
                        );
                        })}
                    </tbody>
                </Table>
            </Container> 
        <Footer/>  
    </>
  )
}

export default SLC;
